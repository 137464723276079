// @flow
import { useInView } from "react-intersection-observer";
import { ButtonLink } from "@nested/component-library";
import { css } from "styled-components";
import { media } from "../../media";
import { ResponsiveImage } from "../../components/ResponsiveImage";
import {
  regularHeading,
  largeHeading,
} from "../../components/Typography/Headings";
import { mediumParagraph } from "../../components/Typography/Paragraphs";

const outerWrapper = css`
  background-color: ${({ theme }) => theme.palette.hague};
  overflow: hidden;
`;

const wrapper = css`
  padding: 40px 20px;
  text-align: center;
  ${media.tablet`
    text-align: left;
    padding: 0;
  `}
  ${media.desktop`
    position: relative;
    max-width: 1180px;
    margin: 0 auto;
  `}
`;

const headingAndIconWrapper = css`
  ${media.tablet`
    padding: 40px 0px 0px;
    max-width: 574px;
    margin: 0 auto;
    min-width: 440px;
    width: calc((100% * 2 / 3) - 40px);
  `}
  ${media.desktop`
    box-sizing: border-box;
    display: inline-block;
    width: calc((100% * 2 / 3) - 20px);
    max-width: 767px;
    padding: 105px 0 105px 20px;
    position: relative;
    &:after {
      content: "";
      height: 100%;
      width: 600px;
      ${({ src, inView }) =>
        inView ? `background-image: url('${src}');` : ""};
      background-size: cover;
      background-position: center;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 100%;
    }
  `}
`;

const iconStyle = css`
  width: 75px;
  height: 75px;
  ${media.tablet`
    width: 100px;
    height: 100px;
  `}
`;

const archwayImageWrapper = css`
  border-radius: 40px 40px 0 0;
  height: 100px;
  width: 80px;
  margin: 0px 20px 0 20px;
  background-color: ${({ theme }) => theme.palette.hague};
  ${({ src, inView }) => (inView ? `background-image: url('${src}');` : "")};
  background-size: cover;
  background-position: center;
  display: inline-block;
  ${media.desktop`
    margin: 0 40px 0 0;
    min-width: 110px;
    height: 137px;
    border-radius: 55px 55px 0 0;
  `}
`;

const headingsContainer = css`
  margin-top: 20px;
  margin-bottom: 0px;
  ${media.tablet`
    margin-top: 10px;
  `}
  ${media.desktop`
    max-width: calc((100% * 5 / 8) - 15px);
  `}
`;

const headingText = css`
  text-align: center;
  margin: 0;
  ${media.tablet`
    text-align: left;
  `}
  span {
    ${regularHeading}
    color: white;
    ${media.tablet`
      ${largeHeading}
      color: white;
    `}
    ${media.desktop`
      display: block;
    `}
  }
`;

const topButtonWrapper = css`
  display: none;
  ${media.tablet`
    display: block;
  `}
`;

const cardPhotoBackground = css`
  ${media.tablet`
    padding: 40px 0px;
    background-color: ${({ theme }) => theme.palette.hague};
    margin-top: 40px;
    ${({ src, inView }) => (inView ? `background-image: url('${src}');` : "")};
    background-size: cover;
    background-position: center;
  `}
  ${media.desktop`
    display: inline-block;
    height: 0px;
    width: 0px;
    margin: 0px;
  `}
`;

const testimonialCard = css`
  background-color: ${({ theme }) => theme.palette.sand100};
  border-radius: 10px;
  padding: 30px 20px;
  text-align: center;
  margin-top: 30px;
  ${media.tablet`
    box-sizing: border-box;
    margin: 0;
    padding: 30px;
    min-width: 440px;
    width: calc((100% * 2 / 3) - 40px);
    margin: 0 auto;
  `}
  ${media.desktop`
    padding: 40px;
    position: absolute;
    display: flex;
    align-items: center;
    top: 50%;
    transform: translateY(-50%);
    left: calc((100% * 5 / 12) + 20px);
    width: calc((100% * 7 / 12) - 40px);
    max-width: 728px;
  `}
`;

const testimonialTextStyle = css`
  ${mediumParagraph}
  margin: 30px 0 0;
  ${media.desktop`
    margin: 0;
    text-align: left;
  `}
`;

const customer = css`
  ${testimonialTextStyle}
  font-weight: 500;
  ${media.desktop`
    margin-top: 20px;
  `}
`;

const bottomButtonWrapper = css`
  ${media.tablet`
    display: none;
  `}
`;

const buttonStyle = css`
  margin-top: 30px;
  &:first-of-type {
    margin-right: 10px;
  }
  &:last-of-type {
    margin-left: 10px;
  }
`;

const Buttons = ({
  accentCtaLink,
  accentCtaText,
  standardCtaLink,
  standardCtaText,
}) => (
  <>
    {accentCtaLink.url && accentCtaText && (
      <ButtonLink
        type="accent"
        to={accentCtaLink.url}
        css={buttonStyle}
        target={accentCtaLink?.target}
      >
        {accentCtaText}
      </ButtonLink>
    )}
    {standardCtaLink.url && standardCtaText && (
      <ButtonLink
        type="standard"
        to={standardCtaLink.url}
        css={buttonStyle}
        target={standardCtaLink?.target}
      >
        {standardCtaText}
      </ButtonLink>
    )}
  </>
);

type Props = {
  heading: PrismicRichText,
  icon: PrismicImage,
  testimonialText: string,
  customerNameAndLocation: string,
  accentCtaText: string,
  accentCtaLink: PrismicLink,
  standardCtaText: string,
  standardCtaLink: PrismicLink,
  smallWindowImage: PrismicImage,
  testimonialBackgroundImage: PrismicImage,
};

export const CustomerTestimonial = ({
  heading,
  icon,
  testimonialText,
  customerNameAndLocation,
  accentCtaText,
  accentCtaLink,
  standardCtaText,
  standardCtaLink,
  smallWindowImage,
  testimonialBackgroundImage,
}: Props) => {
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0,
  });

  return (
    <div ref={ref} css={outerWrapper}>
      <div css={wrapper}>
        <div
          css={headingAndIconWrapper}
          inView={inView}
          src={testimonialBackgroundImage.url}
        >
          {icon.url && (
            <ResponsiveImage src={icon.url} alt={icon.alt} css={iconStyle} />
          )}
          <div css={headingsContainer}>
            <h2 css={headingText}>
              {heading.map(({ text }, index) => (
                <span key={index}>{text} </span>
              ))}
            </h2>
          </div>
          <div css={topButtonWrapper}>
            <Buttons
              accentCtaLink={accentCtaLink}
              accentCtaText={accentCtaText}
              standardCtaLink={standardCtaLink}
              standardCtaText={standardCtaText}
            />
          </div>
        </div>
        <div
          css={cardPhotoBackground}
          src={testimonialBackgroundImage.url}
          inView={inView}
        >
          <div css={testimonialCard}>
            <div
              inView={inView}
              css={archwayImageWrapper}
              src={smallWindowImage.url}
            ></div>
            <div>
              <p css={testimonialTextStyle}>{testimonialText}</p>
              <p css={customer}>{customerNameAndLocation}</p>
            </div>
          </div>
        </div>
        <div css={bottomButtonWrapper}>
          <Buttons
            accentCtaLink={accentCtaLink}
            accentCtaText={accentCtaText}
            standardCtaLink={standardCtaLink}
            standardCtaText={standardCtaText}
          />
        </div>
      </div>
    </div>
  );
};
